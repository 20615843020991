@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&&display=swap');
html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

input,
textarea,
input:focus {
  box-shadow: none;
  border: unset;
  outline: none;
  max-width: 100%;
}
div.react-datepicker-popper {
  z-index: 100;
}

select,
select:focus {
  outline: none;
  box-shadow: none;
  border: unset;
  appearance: none;
  background-color: transparent;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}
select::-ms-expand {
  display: none;
}

a {
  text-decoration: none;
}

.react-datepicker__input-container {
  background: transparent !important;
}

body {
  /* Hide vertical scrollbar */
  overflow-x: hidden !important; /* Hide horizontal scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

html {
  font-size: 10px;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
div[class^='Toastify__toast-container'] {
  position: fixed;
  bottom: 100%;
}

.Toastify__toast {
  position: fixed;
  z-index: 99999;
  width: 100%;
  max-width: 320px;
  left: calc(50% - 160px);
  top: 20px;
  background-color: grey;
  transform: unset !important;
  padding: 12px 16px 12px 16px !important;
  border-radius: 16px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 16px !important;
  min-height: 20px !important;
}

.Toastify__toast--success {
  background-color: #00c569 !important;
  color: #fbf7ef !important;
}

.Toastify__toast--error {
  background-color: #de2c2c !important;
  color: #fbf7ef !important;
}

.Toastify__toast-body {
  max-width: calc(100% - 20px);
  font-size: 16px;
  line-height: 24px;
  padding: 0 !important;
}

.Toastify__close-button {
  position: relative;
  top: 3px;
}

.Toastify__progress-bar {
  height: 3px !important;
}

::-webkit-scrollbar {
  display: none;
}

/* LOADER */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 160px;
  height: 160px;
}
.lds-ripple div {
  top: 50%;
  left: 50%;
  position: absolute;
  border: 4px solid #c19932;
  opacity: 1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
